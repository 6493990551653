<template>
  <div>
    <p>{{ $t('onboarding.taxInformation.descOne') }}</p>
    <p class="grey--text">{{ $t('onboarding.taxInformation.descTwo') }}</p>
    <v-form
      v-model="isFormValid"
      @submit.prevent="submit"
    >
      <!-- TAX NUMBER -->
      <div
        v-for="(id, key) in ids"
        :key="key"
        :class="['d-flex', id.key !== 1 ? 'element pt-8 px-5 rounded-lg' : 'first-id', key > 1 && 'mt-5']"
      >
        <div class="d-flex flex-column flex-sm-row input-container">
          <div class="country-select">
            <country-select
              :items="ewrCountries"
              :rules="rules.required"
              :label="`${$t('onboarding.taxInformation.fields.countryTaxation')} *`"
              :value="id.country"
              @input="changeCountry($event, id.key)"
              :default-country="defaultCountry"
            >
            </country-select>
          </div>
          <div class="tax-number">
            <v-text-field
              id="tax-number"
              type="text"
              :rules="id.country === 'DEU' ? rules.germanTaxNumber : rules.taxNumber"
              required
              :label="`${$t('onboarding.taxInformation.fields.taxNo')} *`"
              outlined
              validate-on-blur
              :ref="`tax-number-${id.key}`"
              :value="id.value"
              @input="changeId($event, id.key, `tax-number-${id.key}`)"
              class="ml-sm-3"
            ></v-text-field>
          </div>
        </div>
        <v-btn
          v-if="id.key !== 1"
          icon
          class="mt-14 mt-sm-2 ml-5"
          @click="deleteId(id.key)"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </div>
    </v-form>

    <div
      v-if="ids.length < 5"
      class="d-flex align-center mt-5"
    >
      <v-btn
        icon
        @click="add"
      >
        <v-icon>
          mdi-plus-circle-outline
        </v-icon>
      </v-btn>
      <span @click="add" class="ml-1 grey--text cursor-pointer">
        {{ $t('onboarding.taxInformation.addBtn') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import clone from '@/utils/clone';
import { required } from '@/utils/validators';
import CountrySelect from '@/components/Shared/InputFields/CountrySelect.vue';

export default {
  name: 'TaxInformation',
  components: {
    CountrySelect,
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      rules: {
        required,
        taxNumber: [
          (v) => !!v || this.$t('validators.taxNumber.required'),
        ],
        germanTaxNumber: [
          (v) => !!v || this.$t('validators.taxNumber.required'),
          (v) => this.validateTIN(v) || this.$t('onboarding.taxInformation.fields.errorMsg'),
        ],
      },
      ids: [
        {
          key: 1,
          country: '',
          value: '',
        },
      ],
    };
  },
  computed: {
    ...mapFields('onboarding', [
      'isFormValid',
    ]),
    ...mapGetters('onboarding', [
      'editMode',
      'ewrCountries',
      'defaultCountry',
    ]),
  },
  created() {
    if (this.data.financialData.countryTaxation && this.data.financialData.taxNo) {
      this.ids[0].country = this.data.financialData.countryTaxation;
      this.ids[0].value = this.data.financialData.taxNo;
    }
    for (let index = 2; index <= 5; index += 1) {
      if (this.data.financialData[`countryTaxation${index}`] && this.data.financialData[`taxNo${index}`]) {
        this.ids.push({
          key: index,
          country: this.data.financialData[`countryTaxation${index}`],
          value: this.data.financialData[`taxNo${index}`],
        });
      }
    }
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'updateOnboarding',
    ]),
    checkValidation(refName) {
      if (!this.isFormValid) {
        this.$refs[refName][0].validate();
      }
    },
    add() {
      this.ids.push({
        key: this.ids[this.ids.length - 1].key + 1,
        country: '',
        value: '',
      });
    },
    deleteId(key) {
      this.ids = this.ids.filter((id) => id.key !== key);
    },
    changeCountry(value, key) {
      const index = this.ids.findIndex((id) => id.key === key);
      this.ids[index].country = value;
    },
    changeId(value, key, ref) {
      const index = this.ids.findIndex((id) => id.key === key);
      this.ids[index].value = value;
      this.checkValidation(ref);
    },
    countValues(arr) {
      return arr.reduce((obj, item) => {
        // obj[item] = obj[item] ? ++obj[item] : 1;
        if (obj[item]) {
          obj[item] += 1;
        } else {
          obj[item] = 1;
        }
        return obj;
      }, {});
    },
    validateTIN(tin) {
      const tinLength = 11;
      // Taxnumber has to have exactly 11 digits.
      if (tin.length !== tinLength) {
        return false;
      }
      // First digit cannot be 0.
      if (tin[0] === '0') {
        return false;
      }
      /*
      make sure that within the first ten digits:
        1.) one digit appears exactly twice or thrice
        2.) one or two digits appear zero times
        3.) and all other digits appear exactly once
      */
      const tinArray = tin.split('').slice(0, -1);
      const valueCount = this.countValues(tinArray);
      const valueCountLength = Object.keys(valueCount).length;
      if (valueCountLength !== 8 && valueCountLength !== 9) {
        return false;
      }
      // 11th digit has to match the checkum.
      let sum = 0;
      let product = 10;
      for (let i = 0; i < tinLength - 1; i += 1) {
        sum = (+tinArray[i] + product) % 10;
        if (sum === 0) {
          sum = 10;
        }
        product = (sum * 2) % 11;
      }
      let checksum = 11 - product;
      if (checksum === 10) {
        checksum = 0;
      }
      return +tin[tinLength - 1] === checksum;
    },
    async submit() {
      const taxInformation = {};
      this.ids.forEach((id, index) => {
        if (index > 0) {
          taxInformation[`taxNo${index + 1}`] = id.value;
          taxInformation[`countryTaxation${index + 1}`] = id.country;
        } else {
          taxInformation.taxNo = id.value;
          taxInformation.countryTaxation = id.country;
        }
      });
      const input = {
        investor: {
          personal: {
            taxInformation,
          },
        },
        mainStage: 'personal',
        subStage: 'taxInformation',
      };
      this.data.financialData = { ...taxInformation };
      for (let index = this.ids.length + 1; index < 5; index += 1) {
        this.data.financialData[`countryTaxation${index}`] = null;
        this.data.financialData[`taxNo${index}`] = null;
      }
      try {
        await this.updateOnboarding({
          input,
          newData: this.data,
        });
        if (this.editMode) {
          this.$store.dispatch('onboarding/editMode', null);
          this.$router.push({
            name: 'Onboarding.Personal',
            params: {
              step: 'Overview',
            },
          });
        } else {
          this.setSubStage('bankaccountinformation');
        }
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
  },
};
</script>

<style scoped>
.input-container {
  width: 100%;
}
.country-select {
  width: 100%;
}
.tax-number {
  width: 100%;
}
.first-id {
  margin-right: 100px;
}
</style>
